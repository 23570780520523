import { css } from "@emotion/react";
import { StyleSheet } from "aphrodite";
import svg from "main/javascripts/images/fonts/iconfont.svg";
import eot from "main/javascripts/images/fonts/iconfontEot.eot";
import woff from "main/javascripts/images/fonts/iconfont.woff";
import ttf from "main/javascripts/images/fonts/iconfont.ttf";

export const iconFont: any = {
  fontFamily: "icomoon",
  src:
    `url('${svg}') format('svg'),` +
    `url('${eot}') format('embedded-opentype'), ` +
    `url('${woff}') format('woff'), url('${ttf}') format('truetype')`,
};

export const iconFontStyles: any = {
  overflow: "hidden",
  margin: 0,
  fontFamily: [iconFont],
  speak: "none",
  fontStyle: "normal",
  fontWeight: "normal",
  fontVariant: "normal",
  textTransform: "none",
  lineHeight: "1",
  "-webkitFontSmoothing": "antialiased",
  "-mozOsxFontSmoothing": "grayscale",
};

// for emotion
const iconFontBaseStyle = css`
  @font-face {
    font-family: "icomoon";
    src: url("${svg}") format("svg"), url("${eot}") format("embedded-opentype"),
      url("${woff}") format("woff"), url("${ttf}") format("truetype");
  }
  overflow: hidden;
  margin: 0;
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const iconStarFull = "\\e900";
const iconStarHalf = "\\e901";
const iconArrowThinL = "\\e902";
const iconArrowThinR = "\\e903";
const iconArrowDown = "\\e904";
const iconArrowUp = "\\e905";
const iconArrowRight = "\\e906";
const iconArrowLeft = "\\e907";
const iconStarLine = "\\e908";
const iconPin = "\\e909";
const iconClose = "\\e90a";
const iconLine = "\\e90b";
const iconFacebook = "\\e90c";
const iconAirplane = "\\e90d";
const iconHotel = "\\e90e";
const iconRestaurant = "\\e90f";
const iconTravelbag = "\\e910";
const iconLoopSwitch = "\\ea2e";
const iconSearch = "\\e911";
const iconCity = "\\e912";
const iconCaution = "\\e913";
const iconInfo = "\\e914";
const iconImages = "\\e915";
const iconCheck = "\\e916";

export interface IIconFontStyle {
  star: any;
  arrowThinL: any;
  arrowThinR: any;
  arrowDown: any;
  arrowUp: any;
  arrowRight: any;
  arrowLeft: any;
  starLine: any;
  pin: any;
  close: any;
  line: any;
  facebook: any;
  airplane: any;
  hotel: any;
  restaurant: any;
  travelbag: any;
  loopSwitch: any;
  search: any;
  city: any;
  caution: any;
  info: any;
  images: any;
  check: any;
}

export const IconFontStyle: IIconFontStyle = StyleSheet.create({
  star: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}"`,
    },
  },
  arrowThinL: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowThinL}"`,
    },
  },
  arrowThinR: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowThinR}"`,
    },
  },
  arrowDown: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowDown}"`,
    },
  },
  arrowUp: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowUp}"`,
    },
  },
  arrowRight: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowRight}"`,
    },
  },
  arrowLeft: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconArrowLeft}"`,
    },
  },
  starLine: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarLine}"`,
    },
  },
  pin: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconPin}"`,
    },
  },
  close: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconClose}"`,
    },
  },
  line: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconLine}"`,
    },
  },
  facebook: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconFacebook}"`,
    },
  },
  airplane: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconAirplane}"`,
    },
  },
  hotel: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconHotel}"`,
    },
  },
  restaurant: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconRestaurant}"`,
    },
  },
  travelbag: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconTravelbag}"`,
    },
  },
  loopSwitch: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconLoopSwitch}"`,
    },
  },
  search: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconSearch}"`,
    },
  },
  city: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconCity}"`,
    },
  },
  caution: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconCaution}"`,
    },
  },
  info: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconInfo}"`,
    },
  },
  images: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconImages}"`,
    },
  },
  check: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconCheck}"`,
    },
  },
});

export interface IStarStyle {
  [index: string]: any;
}

export const StarStyle: IStarStyle = StyleSheet.create({
  star1: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}"`,
    },
  },
  star1_5: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarHalf}"`,
    },
  },
  star2: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}"`,
    },
  },
  star2_5: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarHalf}"`,
    },
  },
  star3: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarFull}"`,
    },
  },
  star3_5: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarFull}${iconStarHalf}"`,
    },
  },
  star4: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}"`,
    },
  },
  star4_5: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}${iconStarHalf}"`,
    },
  },
  star5: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}"`,
    },
  },
  starEmpty: {
    ...iconFontStyles,
    ":before": {
      content: `"${iconStarLine}${iconStarLine}${iconStarLine}${iconStarLine}${iconStarLine}"`,
    },
  },
});

// for emotion

export const starIconStyle: IStarStyle = {
  star1: css`
    ${iconFontBaseStyle};
    &:before {
      content: "${iconStarFull}";
    }
  `,
  star1_5: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarHalf}";
    }
  `,
  star2: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}";
    }
  `,
  star2_5: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarHalf}";
    }
  `,
  star3: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarFull}";
    }
  `,
  star3_5: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarFull}${iconStarHalf}";
    }
  `,
  star4: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}";
    }
  `,
  star4_5: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}${iconStarHalf}";
    }
  `,
  star5: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}${iconStarFull}";
    }
  `,
  starEmpty: css`
    ${iconFontBaseStyle};
    :before {
      content: "${iconStarLine}${iconStarLine}${iconStarLine}${iconStarLine}${iconStarLine}";
    }
  `,
};
