import * as React from "react";
import { css } from "aphrodite";
import {
  IconFontStyle,
  IIconFontStyle,
} from "main/javascripts/styles/fontStyle";

interface IProps {
  children?: React.ReactNode;
  styles?: any;
  styleKey: keyof IIconFontStyle;
}

export const IconComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles, children, styleKey } = props;
  const iconFont: any = IconFontStyle[styleKey];
  return <i className={`${css(iconFont, styles)}`}>{children}</i>;
};
export const Icon: any = React.memo(IconComponent);
